.fav {
    padding: 10px 0;
}
.fav .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4;
}
.header .h1-heading {
    margin-top: 20px;
    background: #000;
    color: #fff;
    padding: 2px 10px;
    width: 80;
    text-align: center;
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}
.header .text-heading {
    margin-top: 20px;
    width: 80%;
}
.fav .fav_container {
    max-width: 90%;
    margin: 20px auto;
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 100px;
}
.favorite_Container {
    position: relative;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    height: 420px;
}
.favorite_Container .favorite_Icon {
    position: absolute;
    top: 5%;
    width: 90%;
    height: 20px;
    display: flex;
    justify-content: flex-end;
}
.favorite_Icon .favoriteCard__wishlist {
    right: 0;
    color: red;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.favorite_Icon .favoriteCard__wishlist:hover {
    cursor: pointer;
    transform: scale(1.3);
}
.favorite_Container .favorite_Image {
    position: absolute;
    height: 200px;
    top: 40px;
    margin: 10px 40px;
    background-position: center;
    background-repeat: no-repeat;
}
.favorite_Container .favorite_Details {
    position: absolute;
    bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
}
.favorite_Details .favorite_Name {
    text-align: left;
    color: #224957;
    font-size: 16px;
    font-weight: 500;
    padding: 2px 0;
}
.favorite_Options {
    color: #224957;
    align-items: center;
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
}
.favorite_Rating {
    color: rgb(168, 166, 166);
}
.favorite_Rating .MuiSvgIcon-root {
    font-size: 15px;
}
.favorite_Details .addBtn {
    color: #fff;
    font-size: 18px;
    padding: 0.75rem 0;
    transition: all .3s ease-in-out;
    width: 100%;
    background: #081b29;
    border: 1px solid #081b29;
    border-radius: 6px;
}
.favorite_Details .addBtn:hover {
    color: #081b29;
    background: #fff;
    cursor: pointer;
}
.fav .resetfav {
    color: #fff;
    font-size: 16px;
    padding: 0.75rem 10px;
    transition: all .3s ease-in-out;
    background: rgb(255, 0, 0);
    border: 1px solid rgb(255, 0, 0);
    border-radius: 6px;
    margin-top: 20px;
}
.fav .resetfav:hover {
    color: rgb(255, 0, 0);
    background: #fff;
    cursor: pointer;
}