.cart_details {
    max-width: 80%;
    margin: 20px auto;
    padding: 20px 0px;
    display: flex;
    text-align: left;
    gap: 20px;
}
.cart_details .cart_totals {
    width: 33%;
    background: #fafafa;
    padding: 10px 10px;
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}
.cart_totals .totals_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.totals_container h2 {
    font-size: 22px;
    font-weight: 500;
}
.totals_container .subtotal {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 300;
}
.totals_container .shipping {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    font-weight: 400;
}
.totals_container .totalamount {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    font-size: 18px;
}
.cart_totals .checkout_button {
    color: #fff;
    font-size: 18px;
    padding: 0.75rem 0;
    transition: all .3s ease-in-out;
    width: 100%;
    background: #081b29;
    border: 1px solid #081b29;
    border-radius: 6px;
}
.cart_totals .checkout_button:hover {
    color: #081b29;
    background: #fff;
    cursor: pointer;
}

@media screen and (max-device-width: 768px) {
    .cart_details {
        flex-direction: column;
        gap: 10px;
    }
    .cart_details .cart_totals {
        width: 100%;
    }
}