header {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    align-items: center;
    padding: 15px 30px;
    background: #fff;
}

header .search-box {
    width: 450px;
    min-width: 250px;
    height: 100%;
    text-align: center;
}

.search-box form {
    background: #bdbdbd;
    border-radius: 25px;
    position: relative;
}

.search-box form input {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 25px;
    font-size: 20px;
    padding: 8px 40px 8px 20px;
    border: 1px solid #bdbdbd;
}

.search-box form input:focus {
    outline: none;
}

form .icon-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 18px;
}

.icon-btn .icon {
    color: #798a9a;
    transition: all 0.3s ease-in-out;
}

.icon-btn .icon:hover {
    color: #4f5a64;
}

header ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

header ul li .user-login-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

header .bar {
    position: relative;
    margin: auto;
    display: none;
}

header .bar span {
    position: absolute;
    color: #fff;
    font-size: 24px;
}

.header-menu {
    -webkit-appearance: none;
    display: none;
}

@media screen and (max-device-width: 768px) {
    header {
        flex-direction: column;
        gap: 10px;
    }

    header .search-box {
        display: inline-block;
        width: 100%;
    }

    header .search-box form {
        width: 90%;
        margin: auto auto;
    }

    header ul {
        display: flex;
        flex-direction: column;
        height: 0;
        visibility: hidden;
        transition: .3s;
    }

    header ul li {
        text-align: center;
        transition: .3s .1s all;
        opacity: 0;
    }

    header ul li a {
        padding: 25px;
        display: block;
    }

    header ul li:nth-child(1) {
        transform: translateX(-150px);
    }

    header ul li:nth-child(2) {
        transform: translateX(-200px);
    }

    header ul li:nth-child(3) {
        transform: translateX(-250px);
    }

    header .bar {
        display: block;
        position: absolute;
        top: 20px;
        right: 80px;
        cursor: pointer;
    }

    header .bar #bars,
    header .bar #times {
        color: #4f5a64;
    }

    header .bar #times {
        display: none;
    }

    #check:checked ~ header .bar #bars {
        display: none;
    }

    #check:checked ~ header .bar #times {
        display: block;
    }

    #check:checked ~ header ul {
        visibility: visible;
        height: 250px;
    }

    #check:checked ~ header ul li:nth-child(1),
    #check:checked ~ header ul li:nth-child(2),
    #check:checked ~ header ul li:nth-child(3) {
        transform: translateX(0);
        opacity: 1;
    }
}