.slider {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    white-space: nowrap;
  }
  .slider .slider_wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .slider_wrapper .slide {
    display: inline-block;
    height: 100%;
    width: 100%;
    position: relative;
  }
  .slider .dots_wrapper {
    z-index: 999;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .dots_wrapper .dot {
    color: rgb(24, 23, 23);
    font-size: 40px;
    font-weight: 700;
    cursor: pointer;
  }
  .dots_wrapper .dot.active {
    color: red;
  }

  @media screen and (max-device-width: 1080px) {
    .slider {
      height: 350px;
      width: 100vw;
    }
  }

  @media screen and (max-device-width: 768px) {
    .slider {
      height: 350px;
      width: 100vw;
    }
  }
  