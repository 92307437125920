.products {
    padding: 10px 0;
}
.products .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4;
}
.header .h1-heading {
    margin-top: 20px;
    background: #000;
    color: #fff;
    padding: 2px 10px;
    width: 80;
    text-align: center;
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}
.header .text-heading {
    margin-top: 20px;
    width: 80%;
}
.products .product_container {
    max-width: 90%;
    margin: 20px auto;
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 100px;
}

@media screen and (max-device-width: 1080px) {
    .products .product_container{
        display: flex;
        flex-direction: column;
    }
}