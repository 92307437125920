.details_container {
    max-width: 80%;
    margin: 20px auto;
    display: flex;
    text-align: left;
    gap: 20px;
}

.details_container .product_container {
    width: 45%;
    height: 100%;
    position: relative;
}

.product_container .product_image {
    object-fit: cover;
}

.details_container .content {
    padding: 10px 20px;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12;
}

.content .product_details {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.product_details h2 {
    color: #224957;
    font-size: 22px;
    font-weight: 500;
}

.product_details h4 {
    color: #224957;
    font-size: 18px;
    font-weight: 500;
}

.product_details .product_Rating {
    display: flex;
    align-items: center;
}

.product_details p {
    color: rgb(97, 97, 97);
    font-weight: 200;
    letter-spacing: 1px;
}

.product_details .prod_qnty {
    width: 52;
    display: flex;
    align-items: center;
}

.prod_qnty .qnty_control {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 20px;
}

.qnty_control button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    margin: auto 10px;
    background: transparent;
    border: none;
    outline: none;
    color: rgb(97, 97, 97);
    transition: all 0.3s ease-in-out;
}

.qnty_control button:hover {
    cursor: pointer;
    background: #000;
    color: #fff;
}

.prod_qnty .addBtn {
    color: #fff;
    font-size: 20px;
    padding: 0.75rem 10px;
    transition: all .3s ease-in-out;
    background: #081b29;
    border: 1px solid #081b29;
    border-radius: 6px;
    width: max-content;
}

.prod_qnty .addBtn:hover {
    color: #081b29;
    background: #fff;
    cursor: pointer;
}

@media screen and (max-device-width: 768px) {
    .details_container {
        flex-direction: column;
    }

    .details_container .product_container,
    .details_container .content {
        width: 100%;
    }
}