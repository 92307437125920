.history_details {
    padding: 10px 0;
}
.history_details .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4;
}
.header .h1-heading {
    margin-top: 20px;
    background: #000;
    color: #fff;
    padding: 2px 10px;
    width: 80;
    text-align: center;
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}
.header .text-heading {
    margin-top: 20px;
    width: 80%;
}
.details {
    position: relative;
    width: 80%;
    margin: 20px auto;
    border: 1px solid rgb(100, 100, 100);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
}
.details .close_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: red;
    font-size: 18px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
}
.details .close_icon:hover{
    transform: scale(1.3);
    cursor: pointer;
    font-weight: 600;
}
.history_table {
    text-align: center; 
    margin: 20px auto;
    width: 100%;
}
.history_table h2 {
    font-size: 22px;
    margin-top: 30px;
}
.Order_Table {
    border: none;
    margin: 0 auto 4rem;
    position: relative;
    width: 80%;
    font-size: 16px;
    font-weight: 400;
    align-items: center;
}
.Order_Table tr {
    background-color: #d9d9d936;
    vertical-align: middle;
    display: table-row;
    text-align: center;
    border-color: inherit;
    height: 60px;
    font-size: 16px;
    align-items: center;
}
.Order_Table tr th {
    display: table-cell;
    font-weight: bold;
    text-align: center;
    border: none;
    padding: 10px;
    border-bottom: 2px solid #000!important;
    font-size: 16px;
}
.OrderTable_totalPrice {
    border-top: 2px solid #fff;
    bottom: -2.5rem;
    margin-top: 10px;
    padding-left: 86%;
    position: absolute;
    text-align: center;
    right: 0;
    text-align: right;
    font-size: 16px;
    width: 100%;
}
.reset_history {
    color: #fff;
    font-size: 16px;
    padding: 0.75rem 10px;
    transition: all .3s ease-in-out;
    background: rgb(255, 0, 0);
    border: 1px solid rgb(255, 0, 0);
    border-radius: 6px;
    margin-top: 20px;
}
.reset_history:hover {
    color: rgb(255, 0, 0);
    background: #fff;
    cursor: pointer;
}