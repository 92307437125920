.cartitem {
    width: 66%;
    padding-right: 10px;
}
.cartitem .cart_items {
    width: 100%;
}
.cart_items h2 {
    font-size: 22px;
    font-weight: 500;
}
.cartitem .item_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 6px;
    text-align: left;
}
.item_details .item_content {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px;
    object-fit: cover;
}
.item_content .close_icon {
    font-size: 18px;
    color: rgb(100, 100, 100);
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}
.item_content .close_icon:hover {
    color: red;
    font-weight: 600;
    cursor: pointer;
}
.item_image {
    width: 100px;
    height: 100px;
}
.item_details h2 {
    font-size: 16px;
    font-weight: 400;
}
.item_details p {
    font-size: 16px;
    font-weight: 600;
}
.item_details .qty_ctrl {
    display: flex;
    gap: 5px;
    align-items: center;
    color: rgb(100, 100, 100);
    border: 1px solid rgb(100, 100, 100);
    padding: 2px 5px;
}
.qty_ctrl p {
    font-size: 16px;
    font-weight: 300;
}
.qty_ctrl .qnty {
    display: flex;
    align-items: center;
}
.qty_ctrl .qnty span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    margin: auto 5px;
    background: transparent;
    border: none;
    outline: none;
    color: rgb(97, 97, 97);
    transition: all 0.3s ease-in-out;
}
.qty_ctrl .qnty span:hover {
    cursor: pointer;
    background: #000;
    color: #fff;
}
.item_details .subtotal {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000;
}
.cartitem .resetcart {
    color: #fff;
    font-size: 16px;
    padding: 0.75rem 10px;
    transition: all .3s ease-in-out;
    background: rgb(255, 0, 0);
    border: 1px solid rgb(255, 0, 0);
    border-radius: 6px;
    margin-top: 20px;
}
.cartitem .resetcart:hover {
    color: rgb(255, 0, 0);
    background: #fff;
    cursor: pointer;
}
.cartitem a button {
    display: flex;
    margin-top: 10px;
    border: none;
    outline: none;
    color: rgb(100, 100, 100);
    background: transparent;
    transition: all 0.3s ease-in-out;
}
.cartitem a button:hover {
    cursor: pointer;
}

@media screen and (max-device-width: 768px) {
    .cartitem{
        width: 100%;
    }
}