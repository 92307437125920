.Product_Container {
    position: relative;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    height: 420px;
}
.Product_Container .product_Icon {
    position: absolute;
    top: 5%;
    width: 90%;
    height: 20px;
    display: flex;
    justify-content: flex-end;
}
.product_Icon .productCard__wishlist {
    right: 0;
    color: red;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.product_Icon .productCard__wishlist:hover {
    cursor: pointer;
    transform: scale(1.3);
}
.Product_Container .Product_Image {
    position: absolute;
    height: 200px;
    top: 40px;
    margin: 10px 40px;
    background-position: center;
    background-repeat: no-repeat;
}
.Product_Image img:hover {
    cursor: pointer;
}
.Product_Container .Product_Details {
    position: absolute;
    bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
}
.Product_Details .product_Name {
    text-align: left;
    color: #224957;
    font-size: 16px;
    font-weight: 500;
    padding: 2px 0;
}
.product_Options {
    color: #224957;
    align-items: center;
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
}
.product_Rating {
    color: rgb(168, 166, 166);
}
.product_Rating .MuiSvgIcon-root {
    font-size: 15px;
}
.Product_Details .addBtn {
    color: #fff;
    font-size: 18px;
    padding: 0.75rem 0;
    transition: all .3s ease-in-out;
    width: 100%;
    background: #081b29;
    border: 1px solid #081b29;
    border-radius: 6px;
}
.Product_Details .addBtn:hover {
    color: #081b29;
    background: #fff;
    cursor: pointer;
}