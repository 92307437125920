.footer_content {
    background: rgb(10, 10, 10);
    color: #fff;
    width: 100%;
}

.footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    background: rgb(10, 10, 10);
    height: auto;
    width: 100%;
    padding: 10px 30px;
}

.footer .footer_left {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.footer .footer_left .logo {
    width: 120px;
    min-width: 120px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.footer .footer_left .logo:hover {
    transform: scale(1.3);
}

.footer p {
    color: #fff;
    margin-bottom: 20px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 200;
}

.footer .footer_left .pay {
    width: 300px;
    transition: all 0.3s ease-in-out;
}

.footer .footer_middle {
    width: 100%;
    align-items: left;
    text-align: left;
}

.footer h3 {
    font-size: 24px;
    color: #fff;
    letter-spacing: 1px;
    margin: 20px 0;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: underline;
}

.footer .footer_right {
    width: 100%;
    display: flex;
}

.footer_right .contact_form form {
    display: flex;
    flex-direction: column;
    width: 450px;
}

.footer_right .contact_form form .input_field {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.input_field input {
    margin-bottom: 5px;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    background: transparent;
    color: #fff;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    border-bottom-left-radius: 10px;
    letter-spacing: 1px;
}

.input_field textarea {
    margin-bottom: 5px;
    width: 100%;
    border: none;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 400;
    background: transparent;
    color: #fff;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    border-bottom-left-radius: 10px;
    letter-spacing: 1px;
    resize: none;
}

.contact_button button {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 6px;
    color: #081b29;
    font-size: 16px;
    padding: 0.75rem;
    transition: all .3s ease-in-out;
    width: max-content;
}

.contact_button button:hover {
    background: #081b29;
    color: #fff;
    cursor: pointer;
}

hr {
    margin: 10px 50px;
}

.copyright p {
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 200;
}

@media screen and (max-device-width: 768px) {
    .footer {
        display: flex;
        flex-direction: column;
        text-align: left;
    }
}