.signin_container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px 0px;
}
.signin_container .signin {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.signin .signin-info {
    width: 250px;
    height: 60px;
    border: 1px solid rgb(100, 100, 100);
    cursor: pointer;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}
.signin .signin-info:hover {
    cursor: pointer;
    border: 1px solid rgb(9, 9, 233);
    font-weight: 600;
}
.signin_container button{
    font-size: 18px;
    width: 250px;
    height: 60px;
    padding: auto;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    transition: all 0.3s ease-in-out;
}
.signin_container button:hover {
    cursor: pointer;
    border: 1px solid rgb(9, 9, 233);
    color: rgb(9, 9, 233);
    background: #fff;
}